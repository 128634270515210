import { Injectable, inject } from '@angular/core';
import { connectCalendar, connectCalendarFinished } from '@cf/temp/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { createEffect, ofType } from '@ngneat/effects';
import { merge, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ModalConnectCalendarComponent } from '../modal-connect-calendar/modal-connect-calendar.component';

@Injectable({ providedIn: 'root' })
export class AuthDialogEffect {
  #modalService = inject(NgbModal);

  connectCalendar$ = createEffect(
    (actions) =>
      actions.pipe(
        ofType(connectCalendar),
        switchMap(() => {
          const modal = this.#modalService.open(ModalConnectCalendarComponent, {
            size: 'medium',
          });
          return merge(modal.dismissed, modal.closed).pipe(
            take(1),
            map(() => {
              return connectCalendarFinished();
            })
          );
        })
      ),
    {
      dispatch: true,
    }
  );
}
