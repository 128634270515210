import { EnvironmentData } from '@cf/core';

export const environment: EnvironmentData = {
  production: true,
  enabledNamespaces: [],
  apiUrl: 'https://api.dev.calfrenzy.bitstudios.dev/',
  appUrl: 'https://dev.calfrenzy.bitstudios.dev',
  publicUrl: 'https://calfrenzy.bitstudios.dev/',
  bookingUrl: 'https://dev.calfrenzy.co/',
  stripeId:
    'pk_test_51Pv0E5Kj2WT4T8Fnv0NuFk76rz3saWwejgTK69skfeObAfq8vDsrTOPKuxlTvS3VbgI9DovMd7ktATJrI3MfdyYa00AlptQRix',
  domain: '.calfrenzy.bitstudios.dev',
  envName: 'dev',
  googleClientId:
    '369573383535-l7ummcbah51d38iccfk1585hvhmnb8np.apps.googleusercontent.com',
  googleMapId: 'AIzaSyAYez6hHF2CrUbDnJ_lA7P-bSj5fbQq89U',
  zoomClientId: 'BvATuJS_RJGIzYSNBxQKA',
  buildVersion: 'ae39947c1a6140643054a612572cdeea368fd90b',
  facebookAppId: '981687840686487',
};
